@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Michroma&display=swap');

:root {
  /* main palette*/
  --fc-green: #008145;
  --light-gray: #efefef;
  --yellow: #ffbc48;
  --dark-gray: #444444;
  /* text palette*/
  --light-text-gray: #939393;
  --dark-text-gray: #707070;
  --green-text: #35804a;
  --yellow-text: #ffbf00;
  --alternate-yellow-text: #E89100;
  --blue-text: #2194d0;
}

* {
  font-family: 'Fira Sans', sans-serif !important;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0px;
  text-rendering: optimizeLegibility;
}

/* main text options */
.lato-font {
  font-family: 'Lato', Arial, Helvetica, sans-serif !important;
}

.fira-font {
  font-family: 'Fira Sans', Arial, Helvetica, sans-serif !important;
}

.michroma-font {
  font-family: 'Michroma', Arial, Helvetica, sans-serif !important;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--dark-text-gray);
  text-shadow: 0px 0px 5px var(--dark-text-gray);
}

.fc-white-text {
  color: #fff !important;
}

.fc-green-text {
  color: var(--green-text) !important;
}

.fc-yellow-text {
  color: var(--yellow-text) !important;
}

.fc-blue-text {
  color: var(--blue-text) !important;
}

.fc-gray-text {
  color: var(--dark-text-gray) !important;
}

.fc-lightgray-text {
  color: var(--light-text-gray) !important;
}

/* topbar */
.fc-topbar-border {
  border-bottom: 1px solid var(--light-gray);
  padding: 5px;
}

.menu-dark-gray {
  background-color: var(--dark-gray);
  color: #fff !important;
}

.fc-green-background {
  background-color: var(--fc-green);
}

.fc-navbar {
  min-height: 45px !important;
  color: #fff !important;
  font-weight: 300 !important;
  font-size: 14px;
  padding: 0 40px !important;
}

.fc-navbar:hover {
  background-color: #282828 !important;
}

/* forcogsa gradient */
.fc-gradient {
  background: rgb(1, 138, 210);
  background: linear-gradient(90deg,
      rgba(1, 138, 210, 1) 0%,
      rgba(98, 48, 137, 1) 35%,
      rgba(0, 149, 65, 1) 50%,
      rgba(228, 20, 18, 1) 65%,
      rgba(255, 236, 0, 1) 100%);
  height: 5px;
}

.fc-bg-video {
  position: fixed;
  z-index: -999;
}

.playBtn {
  background-color: rgba(0, 0, 0, .25);
  border-radius: 50px;
  height: 64px;
  width: 64px;
  vertical-align: middle;
  margin: auto;
}

.playBtn:hover {
  background-color: rgba(0, 0, 0, .65);
}

.green-separator-up {
  background-image: url('./images/backgrounds/back-green.png');
  background-repeat: no-repeat;
  background-size: 100%;
  min-height: 100px;
}

.green-separator-down {
  background-image: url('./images/backgrounds/back-green-2.png');
  background-repeat: no-repeat;
  background-size: 100%;
  min-height: 100px;
}

.fc-subnav-pill>*>a {
  border-radius: 50%;
}

.fc-subnav-pill>*>a:hover {
  background-color: rgba(0, 0, 0, .05) !important;
  border-radius: 50%;
}

.fc-subnav-pill>.uk-active>a {
  background-color: rgba(53, 128, 74, .15) !important;
  border-radius: 100%;
}

.slideshow-height {
  height: 595px !important;
  max-height: 595px !important;
}

.thumb-size {
  width: 92px !important;
  height: 48px !important;
}

.productos-background {
  background: rgb(244, 244, 244);
  background: linear-gradient(120deg, rgba(244, 244, 244, 1) 0%, rgba(244, 244, 244, 1) 50%, rgba(123, 123, 123, 1) 50%, rgba(123, 123, 123, 1) 100%);
}

.fc-button {
  background-color: var(--yellow) !important;
  color: var(--dark-gray) !important;
}

.fc-subnav-proyectos {
  border-bottom: 1px solid var(--light-gray);
}

.fc-subnav-proyectos>*>a {
  border-radius: 5px 5px 0 0;
}

.fc-subnav-proyectos>*>a:hover {
  background-color: rgba(0, 0, 0, .05) !important;
}

.fc-subnav-proyectos>.uk-active>a {
  background-color: rgba(53, 128, 74, 1) !important;
}

.fc-footer {
  background-color: rgba(0, 0, 0, .5);
}


.navigation-bar {
  position: fixed !important;
  margin: auto !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 25px;
  z-index: 9999 !important;
  color: #fff !important;
  width: auto !important;
  height: 275px !important;
  padding: 1rem;
  background-color: rgba(55, 71, 79, 0);
  color: #fff !important;
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  border-radius: 8px;
  opacity: .75;
}
.hoverProduct {
  
}